<template>
  <Component :is="resolveErrorComponent" />
</template>

<script lang="ts" setup>
const error = useError();

const resolveErrorComponent = computed(() => {
  if (error.value === null || error.value === undefined) {
    return resolveComponent("SharedError500");
  }

  switch (error.value.statusCode) {
    case 403: {
      return resolveComponent("SharedError403");
    }

    case 404: {
      return resolveComponent("SharedError404");
    }

    case 500: {
      return resolveComponent("SharedError500");
    }

    default: {
      return resolveComponent("SharedError500");
    }
  }
});
</script>
