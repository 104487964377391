const pageScrollBlock = () => {
  const currentScrollPosition = window.scrollY;
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  document.documentElement.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`);
  document.documentElement.style.setProperty("--scroll-position", `-${currentScrollPosition}px`);
  document.documentElement.classList.add("blocked");
};
const pageScrollComputedWidth = () => {
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  document.documentElement.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`);
};
const pageScrollUnblock = () => {
  const scrollPosition = document.documentElement.style.getPropertyValue("--scroll-position");
  const currentScrollPosition = Number(scrollPosition.replace("px", "")) * -1;
  document.documentElement.classList.remove("blocked");
  document.documentElement.style.setProperty("--scroll-position", `0px`);
  window.scrollTo(0, currentScrollPosition);
};
export { pageScrollBlock, pageScrollComputedWidth, pageScrollUnblock };
