import type { NitroFetchOptions, NitroFetchRequest } from "nitropack";

const useFetchData = async <T>(endpoint: string, options: NitroFetchOptions<NitroFetchRequest> = {}) => {
  const { $env } = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();
  const { tokenState } = useAuthStore();
  const restConfig = runtimeConfig.public.rest;
  const date = new Intl.DateTimeFormat();
  const dateOptions = date.resolvedOptions();
  const userTimezone = generateDateLibrary.tz.guess();

  const defaultParameters: NitroFetchOptions<NitroFetchRequest> = {
    baseURL: restConfig.endpoint,
    headers: {
      ...(tokenState.value.accessToken && {
        Authorization: `Bearer ${tokenState.value.accessToken}`,
      }),
      ...(dateOptions.locale && {
        "X-Language": dateOptions.locale,
      }),
      ...(userTimezone && {
        "X-Timezone": userTimezone,
      }),
    },
    async onRequest() {
      if ($env.isClient) {
        await useAuthUpdateAccessToken();
      }
    },
  };

  const parameters = mergeData(defaultParameters, options);

  return fetchRequest<T>(endpoint, parameters);
};

export { useFetchData };
