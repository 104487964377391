var SourceType = /* @__PURE__ */ ((SourceType2) => {
  SourceType2["ARRAY"] = "array";
  SourceType2["DATE"] = "date";
  SourceType2["MAP"] = "map";
  SourceType2["NOT"] = "not";
  SourceType2["OBJECT"] = "record";
  SourceType2["OTHER"] = "other";
  SourceType2["REGEXP"] = "regexp";
  SourceType2["SET"] = "set";
  return SourceType2;
})(SourceType || {});
const isObject = (value) => {
  const validObjectToStringValues = /* @__PURE__ */ new Set(["[object Object]", "[object Module]"]);
  if (!validObjectToStringValues.has(Object.prototype.toString.call(value))) {
    return false;
  }
  const { constructor } = value;
  if (constructor === void 0) {
    return true;
  }
  const prototype = constructor.prototype;
  if (prototype === null || typeof prototype !== "object" || !validObjectToStringValues.has(Object.prototype.toString.call(prototype))) {
    return false;
  }
  return Object.hasOwn(prototype, "isPrototypeOf");
};
const getSourceType = (source) => {
  if (typeof source !== "object" || source === null) {
    return "not" /* NOT */;
  }
  if (Array.isArray(source)) {
    return "array" /* ARRAY */;
  }
  if (isObject(source)) {
    return "record" /* OBJECT */;
  }
  if (source instanceof Date) {
    return "set" /* SET */;
  }
  if (source instanceof Set) {
    return "set" /* SET */;
  }
  if (source instanceof Map) {
    return "map" /* MAP */;
  }
  if (source instanceof RegExp) {
    return "regexp" /* REGEXP */;
  }
  return "other" /* OTHER */;
};
const setProperty = (object, key, source, value) => {
  if (value?.value instanceof Object) {
    value.value = copyData(value.value);
  }
  if (key === "__proto__" || key === "constructor") {
    Object.defineProperty(object, key, {
      configurable: true,
      enumerable: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define -- Необходима рекурсия
      value: copyData(source[key]),
      writable: true
    });
  } else {
    object[key] = value?.value;
  }
};
const copy = (newData, source) => {
  for (let index = 0, list = Object.getOwnPropertySymbols(source); index < list.length; index++) {
    setProperty(newData, list[index], source, Object.getOwnPropertyDescriptor(source, list[index]));
  }
  for (let index = 0, list = Object.getOwnPropertyNames(source); index < list.length; index++) {
    setProperty(newData, list[index], source, Object.getOwnPropertyDescriptor(source, list[index]));
  }
  return newData;
};
const copyData = (source) => {
  const type = getSourceType(source);
  switch (type) {
    case "record" /* OBJECT */: {
      const prototype = Object.getPrototypeOf(source);
      const newObject = Object.create(prototype);
      return copy(newObject, source);
    }
    case "array" /* ARRAY */: {
      const newArray = Array.from({ length: source.length });
      return copy(newArray, source);
    }
    case "set" /* SET */: {
      const newSet = /* @__PURE__ */ new Set();
      source.forEach((value) => newSet.add(copyData(value)));
      return copy(newSet, source);
    }
    case "map" /* MAP */: {
      const newMap = /* @__PURE__ */ new Map();
      source.forEach((value, key) => {
        return newMap.set(copyData(key), copyData(value));
      });
      return copy(newMap, source);
    }
    case "date" /* DATE */: {
      return new Date(Number(source));
    }
    case "regexp" /* REGEXP */: {
      return new RegExp(source.source, source.flags);
    }
    default: {
      return source;
    }
  }
};
export { copyData };
