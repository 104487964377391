<template>
  <Teleport to="#teleports">
    <TransitionGroup
      v-if="notificationState.notifications.length > 0"
      class="c-notification__container"
      name="show"
      tag="ul"
      @mouseenter="() => notificationState.notifications.forEach((notification) => notification.timeout?.stop())"
    >
      <li
        v-for="notification in notificationState.notifications"
        v-show="notification.isShow"
        :id="notification.id"
        :key="notification.id"
        class="c-notification"
        :class="notification.type"
        @click="() => closeNotification(notification.id)"
        @touchmove.passive="() => closeNotification(notification.id)"
      >
        <div v-html="notification.message" />

        <BaseButton2 class="c-notification__button">
          <BaseIcon2
            class="c-notification__button-icon"
            viewBox="0 0 16 16"
          >
            <path
              d="m8.92 8 4.89-4.89a.66.66 0 0 0 0-.92.66.66 0 0 0-.92 0L8 7.08 3.11 2.19a.66.66 0 0 0-.92 0 .66.66 0
                0 0 0 .92L7.08 8l-4.89 4.89a.66.66 0 0 0 0 .92c.13.13.29.19.46.19.17 0 .33-.06.46-.19L8 8.92l4.89
                4.89c.13.13.29.19.46.19.17 0 .33-.06.46-.19a.66.66 0 0 0 0-.92L8.92 8Z"
              fill="currentColor"
            />
          </BaseIcon2>
        </BaseButton2>
      </li>
    </TransitionGroup>
  </Teleport>
</template>

<script lang="ts" setup>
const { closeNotification, notificationState } = useNotificationStore();
</script>

<style scoped>
.c-notification__container {
  position: fixed;
  z-index: var(--z-index-notification);
}
</style>
