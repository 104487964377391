<template>
  <button
    ref="buttonElement"
    class="base-button"
    :class="{ loading }"
    :disabled="loading"
    :type
  >
    <BaseLoader2
      v-if="loading"
      class="base-button__loader"
      :class="classLoader"
      :size="20"
    />

    <slot />
  </button>
</template>

<script lang="ts" setup>
import type { ButtonHTMLAttributes } from "vue";

interface Props extends /* @vue-ignore */ ButtonHTMLAttributes {
  classLoader?: string;
  loading?: boolean;
  type?: ButtonHTMLAttributes["type"];
}

withDefaults(defineProps<Props>(), {
  type: "button",
});

defineSlots<{
  default: [];
}>();

const buttonElement = ref<HTMLButtonElement | null>(null);

defineExpose({
  buttonElement,
});
</script>

<style scoped>
.base-button {
  position: relative;
}

.base-button.loading {
  color: transparent;
}

.base-button.loading > *:not(.base-button__loader) {
  visibility: hidden;
  opacity: 0%;
}

.base-button__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}
</style>
