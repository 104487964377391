import { useLazyQuery, useQuery } from "@vue/apollo-composable";

import type { ApolloError, ApolloQueryResult, OperationVariables } from "@apollo/client/core";
import type { UseLazyQueryReturn } from "@vue/apollo-composable/dist/useLazyQuery";
import type {
  DocumentParameter,
  OnErrorContext,
  OnResultContext,
  UseQueryReturn,
} from "@vue/apollo-composable/dist/useQuery.js";

const useGraphqlQuery = async <TResult, TVariables extends OperationVariables>(
  document: DocumentParameter<TResult, TVariables>,
  variables?: GraphqlQueryVariables<TVariables>,
  options?: GraphqlQueryOptions<TResult, TVariables>,
): Promise<UseQueryReturn<TResult, TVariables>> => {
  const query = useQuery<TResult, TVariables>(
    document,
    variables ?? ({} as GraphqlQueryVariables<TVariables>),
    options ?? ({} as GraphqlQueryOptions<TResult, TVariables>),
  );

  await new Promise((resolve, reject) => {
    query.onResult((result, context) => {
      if (!result.loading) {
        query.onResult = (callback: (parameter: ApolloQueryResult<TResult>, context: OnResultContext) => void) => {
          callback(result, context);

          return {
            off: () => {
              return null;
            },
          };
        };

        resolve(result);
      }
    });

    query.onError((error, context) => {
      query.onError = (callback: (parameter: ApolloError, context: OnErrorContext) => void) => {
        callback(error, context);

        return {
          off: () => {
            return null;
          },
        };
      };

      reject(error);
    });
  });

  return query;
};

const useGraphqlAsyncQuery = <TResult, TVariables extends OperationVariables>(
  document: DocumentParameter<TResult, TVariables>,
  variables?: GraphqlQueryVariables<TVariables>,
  options?: GraphqlQueryOptions<TResult, TVariables>,
) => {
  return useQuery<TResult, TVariables>(
    document,
    variables ?? ({} as GraphqlQueryVariables<TVariables>),
    options ?? ({} as GraphqlQueryOptions<TResult, TVariables>),
  );
};

const useGraphqlLazyQuery = <TResult, TVariables extends OperationVariables>(
  document: DocumentParameter<TResult, TVariables>,
  variables?: GraphqlQueryVariables<TVariables>,
  options?: GraphqlQueryOptions<TResult, TVariables>,
): UseLazyQueryReturn<TResult, TVariables> => {
  return useLazyQuery(document, variables, options);
};

export { useGraphqlAsyncQuery, useGraphqlLazyQuery, useGraphqlQuery };
