const useAuthUpdateAccessTokenDto = async (payload: UpdateAccessTokenPayload) => {
  const runtimeConfig = useRuntimeConfig();
  const restConfig = runtimeConfig.public.rest;

  const response = await fetchRequest<FetchData<AuthToken>>(`${restConfig.endpoint}/auth/access-token`, {
    body: payload,
    method: "POST",
  });

  return {
    ...response,
  };
};

export { useAuthUpdateAccessTokenDto };
