import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client/core";
import { loadDevMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { RetryLink } from "@apollo/client/link/retry";
import { provideApolloClients } from "@vue/apollo-composable";

import type { ApolloClientOptions, NormalizedCacheObject } from "@apollo/client/core";

const CACHE_KEY = "__DEFAULT_APOLLO_CACHE__";

export default defineNuxtPlugin({
  name: "apollo",
  setup: (nuxtApp) => {
    const { tokenState } = useAuthStore();
    const { $env } = useNuxtApp();
    const { origin } = useRequestURL();
    const runtimeConfig = useRuntimeConfig();
    const removeTypenameLink = removeTypenameFromVariables();
    const graphqlConfig = runtimeConfig["public"].graphql;

    if ($env.isDev) {
      loadDevMessages();
    }

    const date = new Intl.DateTimeFormat();
    const dateOptions = date.resolvedOptions();

    const authLink = setContext((_, { headers, ...context }) => {
      return {
        headers: {
          ...headers,
          ...(tokenState.value.accessToken && {
            Authorization: `Bearer ${tokenState.value.accessToken}`,
          }),
          ...(dateOptions.locale && {
            "X-Language": dateOptions.locale,
          }),
          ...(dateOptions.timeZone && {
            "X-Timezone": dateOptions.timeZone,
          }),
        } as HeadersInit,
        ...context,
      };
    });

    const onBeforeApiRequestLink = setContext(async () => {
      if ($env.isClient) {
        await useAuthUpdateAccessToken();
      }
    });

    const errorLink = onError(({ forward, graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          // TODO: Обработать ошибки
          console.error(error);
        }
      }

      if (networkError) {
        // TODO: Обработать ошибки
        console.error(networkError);
      }

      forward(operation);
    });

    const retryLink = new RetryLink();

    const httpLink = new HttpLink({
      uri: origin + graphqlConfig.endpoint,
    });

    const cache = new InMemoryCache();

    if ($env.isClient) {
      nuxtApp.hook("app:created", () => {
        cache.restore(destr(JSON.stringify(nuxtApp.payload.data[CACHE_KEY])));
      });
    }

    if ($env.isServer) {
      nuxtApp.hook("app:rendered", () => {
        nuxtApp.payload.data[CACHE_KEY] = cache.extract();
      });
    }

    const apolloClient = new ApolloClient({
      cache,
      defaultOptions: {
        mutate: {
          awaitRefetchQueries: true,
        },
      },
      link: from([onBeforeApiRequestLink, authLink, errorLink, removeTypenameLink, retryLink, httpLink]),
      ...($env.isClient && {
        ssrForceFetchDelay: 100,
      }),
      ...($env.isDev && {
        devtools: {
          enabled: true,
        },
      }),
      ...($env.isServer && {
        ssrMode: true,
      }),
    } as ApolloClientOptions<NormalizedCacheObject>);

    provideApolloClients({
      default: apolloClient,
    });
  },
});
