const getRefreshToken = () => {
  const { appHostname } = useAppLink();

  const refreshToken = useCookie(SHARED_COOKIE.RefreshTokenCRM, {
    domain: `.${appHostname}`,
  });

  const expiresRefreshToken = useCookie(SHARED_COOKIE.RefreshTokenExpiresCRM, {
    domain: `.${appHostname}`,
  });

  return {
    expiresRefreshToken,
    refreshToken,
  };
};

const removeRefreshToken = () => {
  const { appHostname } = useAppLink();

  const refreshTokenCookie = useCookie(SHARED_COOKIE.RefreshTokenCRM, {
    domain: `.${appHostname}`,
  });

  const refreshTokenExpiresCookie = useCookie(SHARED_COOKIE.RefreshTokenExpiresCRM, {
    domain: `.${appHostname}`,
  });

  refreshTokenCookie.value = null;
  refreshTokenExpiresCookie.value = null;
};

const setRefreshTokenCookie = (result: AuthToken) => {
  const { appHostname } = useAppLink();

  const refreshTokenCookie = useCookie(SHARED_COOKIE.RefreshTokenCRM, {
    domain: `.${appHostname}`,
    expires: date.unix(result.expires_refresh_token).toDate(),
  });

  const refreshTokenExpiresCookie = useCookie(SHARED_COOKIE.RefreshTokenExpiresCRM, {
    domain: `.${appHostname}`,
    expires: date.unix(result.expires_refresh_token).toDate(),
  });

  refreshTokenCookie.value = result.refresh_token;
  refreshTokenExpiresCookie.value = String(result.expires_refresh_token);
};

const useAuthToken = () => {
  return {
    getRefreshToken,
    removeRefreshToken,
    setRefreshTokenCookie,
  };
};

export { useAuthToken };
