const useGetClientDto = async (
  variables?: GetClientQueryVariables,
  options?: GraphqlQueryOptions<GetClientQuery, GetClientQueryVariables>,
) => {
  const query = await useGraphqlQuery(GetClientSchema, variables, options);
  const item = computed(() => query.result.value?.client ?? null);

  return {
    ...query,
    item,
  };
};

export { useGetClientDto };
