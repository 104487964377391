const useClientStore = () => {
  const clientState = useState<Client | null>("client", () => null);

  const setClient = (client: Client) => {
    clientState.value = client;
  };

  return {
    client: clientState,
    setClient,
  };
};

export { useClientStore };
