<template>
  <Component
    :is="renderComponent.component"
    class="c-link"
    v-bind="renderComponent.props"
    @click="(event) => emits('click', event)"
  >
    <BaseLoader2
      v-if="loading"
      class="c-link__loading"
      :size="20"
    />

    <slot />
  </Component>
</template>

<script lang="ts" setup>
import { BaseButton2 } from "#components";

import type { AnchorHTMLAttributes } from "vue";

interface Props extends /* @vue-ignore */ AnchorHTMLAttributes {
  activeClass?: string;
  disabled?: boolean;
  exactActiveClass?: string;
  external?: boolean;
  loading?: boolean;
  prefetch?: boolean;
  prefetchedClass?: string;
  replace?: boolean;
  to?: RouteLocationRaw;
}

interface LinkComponent {
  component: Component | string;
  props?: Props;
}

const props = defineProps<Props>();

const emits = defineEmits<{
  click: [event];
}>();

defineSlots<{
  default: [];
}>();

const { activeClass, disabled, exactActiveClass, external, loading, prefetch, prefetchedClass, replace, to } =
  toRefs(props);

const NuxtLink: Component = defineNuxtLink({
  componentName: "BaseLink",
});

const renderComponent = computed<LinkComponent>(() => {
  if (disabled.value || loading.value) {
    return {
      component: BaseButton2,
      props: {
        class: {
          disabled: disabled.value,
          loading: loading.value,
        },
        disabled: disabled.value,
      },
    };
  }

  return {
    component: NuxtLink,
    props: {
      activeClass: activeClass.value,
      exactActiveClass: exactActiveClass.value,
      external: external.value,
      prefetch: prefetch.value,
      prefetchedClass: prefetchedClass.value,
      replace: replace.value,
      to: to.value,
    },
  };
});
</script>

<style scoped>
.c-link {
  position: relative;
}

.c-link.loading {
  color: transparent;
}

.c-link.loading > *:not(.c-link__loading) {
  visibility: hidden;
  opacity: 0%;
}

.c-link__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}
</style>
