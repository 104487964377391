<template>
  <div
    class="base-loading-progress"
    :style="loadingProgressStyle"
  />
</template>

<script lang="ts" setup>
const { progress } = useLoadingIndicator();

const loadingProgressStyle = computed(() => {
  return {
    backgroundSize: `${(100 / progress.value) * 100}% auto`,
    opacity: progress.value ? 1 : 0,
    transform: `scaleX(${progress.value}%)`,
  };
});
</script>

<style scoped>
.base-loading-progress {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-notification);
  transform-origin: left;
  transition-property: transform, opacity;
  transition-duration: 0.1s, 0.4s;
  pointer-events: none;
}
</style>
