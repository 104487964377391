export default defineNuxtRouteMiddleware(async () => {
  const { client, setClient } = useClientStore();

  if (client.value) {
    return;
  }

  const { tokenState } = useAuthStore();
  const { getRefreshToken, removeRefreshToken } = useAuthToken();
  const { refreshToken } = getRefreshToken();

  if (refreshToken.value && tokenState.value.accessToken) {
    const { item } = await useGetClientDto();

    if (item.value) {
      setClient(item.value);
    }

    if (!item.value) {
      const { createAppLink } = useAppLink();
      const { hash, pathname, search } = useRequestURL();
      const redirectLink = createAppLink("/", "main");
      const redirectToLink = createAppLink(pathname + search + hash, "main");

      removeRefreshToken();

      return navigateTo(
        {
          path: redirectLink,
          query: {
            modal: SHARED_MODAL.AuthLogin,
            [SHARED_URL_QUERY_PARAMETERS.RedirectTo]: redirectToLink,
          },
        },
        {
          external: true,
        },
      );
    }
  }
});
