const useAuthSocialError = () => {
  const { openNotification } = useNotificationStore();
  const route = useRoute();
  const router = useRouter();

  if (route.query?.[SHARED_URL_QUERY_PARAMETERS.ErrorSocialAuth]) {
    const errorMessages = {
      "403": "Этот аккаунт уже используется другим пользователем",
      "404": "Ваш аккаунт был удален",
    };

    const message = errorMessages[route.query[SHARED_URL_QUERY_PARAMETERS.ErrorSocialAuth]] || localeRuErrors.internal;

    openNotification(message, {
      type: "error",
    });

    const queryParameters = copyData(route.query);

    delete queryParameters[SHARED_URL_QUERY_PARAMETERS.ErrorSocialAuth];

    router.push({
      query: queryParameters,
    });
  }
};

export { useAuthSocialError };
