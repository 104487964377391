enum SHARED_MODAL {
  AuthLogin = "modal-auth-login",
  Chat = "modal-chat",
  DeleteWarning = "modal-delete-warning",
  Filters = "modal-filters",
  ProjectCreate = "modal-project-create",
  Sort = "modal-sort",
  UpdateWarning = "modal-update-warning",
}

export { SHARED_MODAL };
