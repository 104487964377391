import type { Stoppable } from "@vueuse/core";

export type NotificationType = "error" | "info" | "success" | "warning";

interface Notification {
  duration: number | "Infinity";
  id: string;
  isShow: boolean;
  message: string;
  timeout?: Stoppable;
  type: NotificationType;
}

interface NotificationState {
  notifications: Notification[];
}

interface NotificationOptions {
  duration?: number | "Infinity";
  id?: string;
  isShow?: boolean;
  preventDuplicate?: boolean;
  type?: NotificationType;
}

const DEFAULT_NOTIFICATION_DURATION = 3500;
const DEFAULT_NOTIFICATION_TYPE = "info";
const NUMBER_VISIBLE_NOTIFICATION = 3;

const useNotificationStore = () => {
  const notificationState = useState<NotificationState>("notificationState", () => {
    return {
      notifications: [],
    };
  });

  const closeNotification = (id: string) => {
    notificationState.value.notifications = notificationState.value.notifications.filter((item) => item.id !== id);
  };

  const openNotification = (
    message: string,
    {
      duration = DEFAULT_NOTIFICATION_DURATION,
      isShow = false,
      preventDuplicate = false,
      type = DEFAULT_NOTIFICATION_TYPE,
    } = {} as NotificationOptions,
  ) => {
    if (!message) {
      return;
    }

    if (preventDuplicate) {
      const oldNotification = notificationState.value.notifications.find(
        (notification) => notification.message === message,
      );

      if (oldNotification) {
        return;
      }
    }

    const notification: Notification = {
      duration,
      id: generateUniqId(),
      isShow,
      message,
      type,
    };

    if (notification.duration !== "Infinity") {
      notification.timeout = useTimeoutFn(() => closeNotification(notification.id), notification.duration, {
        immediate: false,
      });
    }

    notificationState.value.notifications.unshift(notification);

    const index = notificationState.value.notifications.findIndex(
      (currentNotification) => currentNotification.id === notification.id,
    );

    if (index !== -1 && index < NUMBER_VISIBLE_NOTIFICATION) {
      notification.isShow = true;
      notification.timeout?.start();
    }
  };

  return {
    closeNotification,
    notificationState,
    openNotification,
  };
};

export { useNotificationStore };
