enum SHARED_COOKIE {
  RecordOnlineHubCV = "donut-record-online-hub-cv",
  RefreshTokenCRM = "donut-refresh-token-crm",
  RefreshTokenCV = "donut-refresh-token-cv",
  RefreshTokenExpiresCRM = "donut-refresh-token-expires-crm",
  RefreshTokenExpiresCV = "donut-refresh-token-expires-cv",
  UserCityCV = "donut-user-city-cv",
}

export { SHARED_COOKIE };
