const useAuthUpdateAccessToken = async () => {
  const MIN_TIME_ACCESS_TOKEN = 120; // 2 минуты
  const { setAuthToken, toggleTokenUpdate, tokenState } = useAuthStore();
  const { getRefreshToken } = useAuthToken();
  const { refreshToken } = getRefreshToken();

  if (!tokenState.value.accessTokenExpires || !refreshToken.value) {
    return;
  }

  const accessTokenExpiresDate = date.unix(tokenState.value.accessTokenExpires);
  const remainingMinutes = accessTokenExpiresDate.diff(date(), "second");

  if (remainingMinutes > MIN_TIME_ACCESS_TOKEN || tokenState.value.isTokenUpdate) {
    return;
  }

  toggleTokenUpdate(true);

  const { body } = await useAuthUpdateAccessTokenDto({
    refresh_token: refreshToken.value,
  });

  toggleTokenUpdate(false);

  if (body.data) {
    setAuthToken(body.data);
  }
};

export { useAuthUpdateAccessToken };
