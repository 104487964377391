const fetchRequest = async (endpoint, options = {}) => {
  const defaultOptions = {
    retry: 5
  };
  const allOptions = mergeData(defaultOptions, options);
  try {
    const { _data, headers, ok, status } = await $fetch.raw(endpoint, allOptions);
    return {
      body: _data ?? {},
      headers,
      status,
      success: ok
    };
  } catch (error) {
    return {
      body: error.data,
      headers: {},
      status: 500,
      success: false
    };
  }
};
export { fetchRequest };
