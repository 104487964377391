var SourceType = /* @__PURE__ */ ((SourceType2) => {
  SourceType2["ARRAY"] = "array";
  SourceType2["MAP"] = "map";
  SourceType2["NOT"] = "not";
  SourceType2["OBJECT"] = "record";
  SourceType2["OTHER"] = "other";
  SourceType2["SET"] = "set";
  return SourceType2;
})(SourceType || {});
const isObject = (value) => {
  const validObjectToStringValues = /* @__PURE__ */ new Set(["[object Object]", "[object Module]"]);
  if (!validObjectToStringValues.has(Object.prototype.toString.call(value))) {
    return false;
  }
  const { constructor } = value;
  if (constructor === void 0) {
    return true;
  }
  const prototype = constructor.prototype;
  if (prototype === null || typeof prototype !== "object" || !validObjectToStringValues.has(Object.prototype.toString.call(prototype))) {
    return false;
  }
  return Object.hasOwn(prototype, "isPrototypeOf");
};
const getSourceType = (source) => {
  if (typeof source !== "object" || source === null) {
    return "not" /* NOT */;
  }
  if (Array.isArray(source)) {
    return "array" /* ARRAY */;
  }
  if (isObject(source)) {
    return "record" /* OBJECT */;
  }
  if (source instanceof Set) {
    return "set" /* SET */;
  }
  if (source instanceof Map) {
    return "map" /* MAP */;
  }
  return "other" /* OTHER */;
};
const getObjectKeys = (objects) => {
  const keys = /* @__PURE__ */ new Set();
  for (const object of objects) {
    for (const key of [...Object.keys(object), ...Object.getOwnPropertySymbols(object)]) {
      keys.add(key);
    }
  }
  return keys;
};
const hasObjectProperty = (object, property) => {
  return typeof object === "object" && Object.prototype.propertyIsEnumerable.call(object, property);
};
const getIterableOfIterables = (iterables) => {
  return {
    *[Symbol.iterator]() {
      for (const iterable of iterables) {
        for (const value of iterable) {
          yield value;
        }
      }
    }
  };
};
const mergeOthers = (values) => {
  return values.at(-1);
};
const mergeObjects = (values) => {
  const result = {};
  for (const key of getObjectKeys(values)) {
    const propertyValues = [];
    for (const value of values) {
      if (hasObjectProperty(value, key)) {
        propertyValues.push(value[key]);
      }
    }
    if (propertyValues.length === 0) {
      continue;
    }
    const propertyResult = mergeUnknowns(propertyValues);
    if (key === "__proto__") {
      Object.defineProperty(result, key, {
        configurable: true,
        enumerable: true,
        value: propertyResult,
        writable: true
      });
    } else {
      result[key] = propertyResult;
    }
  }
  return result;
};
const mergeArrays = (values) => {
  return values.flat();
};
const mergeSets = (values) => {
  return new Set(getIterableOfIterables(values));
};
const mergeMaps = (values) => {
  return new Map(getIterableOfIterables(values));
};
const mergeUnknowns = (values) => {
  if (values.length === 0) {
    return void 0;
  }
  if (values.length === 1) {
    return mergeOthers(values);
  }
  const type = getSourceType(values[0]);
  if (type !== "not" /* NOT */ && type !== "other" /* OTHER */) {
    for (let index = 1; index < values.length; index++) {
      if (getSourceType(values[index]) === type) {
        continue;
      }
      return mergeOthers(values);
    }
  }
  switch (type) {
    case "record" /* OBJECT */: {
      return mergeObjects(values);
    }
    case "array" /* ARRAY */: {
      return mergeArrays(values);
    }
    case "set" /* SET */: {
      return mergeSets(values);
    }
    case "map" /* MAP */: {
      return mergeMaps(values);
    }
    default: {
      return mergeOthers(values);
    }
  }
};
const mergeData = (...values) => {
  return mergeUnknowns(values);
};
export { mergeData };
