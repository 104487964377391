interface AuthTokenState {
  accessToken: string | null;
  accessTokenExpires: number | null;
  isTokenUpdate: boolean;
}

const useAuthStore = () => {
  const tokenState = useState<AuthTokenState>("auth-token", () => {
    return {
      accessToken: null,
      accessTokenExpires: null,
      isTokenUpdate: false,
    };
  });

  const setAuthToken = (result: AuthToken) => {
    tokenState.value.accessToken = result.access_token;
    tokenState.value.accessTokenExpires = result.expires_access_token;
  };

  const toggleTokenUpdate = (state: AuthTokenState["isTokenUpdate"]) => {
    tokenState.value.isTokenUpdate = state;
  };

  return {
    setAuthToken,
    toggleTokenUpdate,
    tokenState,
  };
};

export { useAuthStore };
