import { default as cookiemOAxaPzMUYMeta } from "/app/apps/main/pages/about/cookie.vue?macro=true";
import { default as license_45agreementkMCPmzGS60Meta } from "/app/apps/main/pages/about/license-agreement.vue?macro=true";
import { default as privacy_45policyGmXsOoYOXcMeta } from "/app/apps/main/pages/about/privacy-policy.vue?macro=true";
import { default as user_45agreementoTBL7dn38CMeta } from "/app/apps/main/pages/about/user-agreement.vue?macro=true";
import { default as contactsJOJFOzPHdXMeta } from "/app/apps/main/pages/client/about/contacts.vue?macro=true";
import { default as indexBs7SYpuJobMeta } from "/app/apps/main/pages/client/about/index.vue?macro=true";
import { default as questionsZkYGPOPQK7Meta } from "/app/apps/main/pages/client/about/questions.vue?macro=true";
import { default as ratesDA2lxZX7FqMeta } from "/app/apps/main/pages/client/about/rates.vue?macro=true";
import { default as indexahoCwrwkCWMeta } from "/app/apps/main/pages/client/index.vue?macro=true";
import { default as profilehwEWxnxaMzMeta } from "/app/apps/main/pages/client/profile.vue?macro=true";
import { default as projectsTzwPWuFgDlMeta } from "/app/apps/main/pages/client/projects.vue?macro=true";
import { default as contactslJPNFNcbUzMeta } from "/app/apps/main/pages/contacts.vue?macro=true";
import { default as botYfoz4JhNx4Meta } from "/app/apps/main/pages/features/bot.vue?macro=true";
import { default as chatswSyv7QNytqMeta } from "/app/apps/main/pages/features/chats.vue?macro=true";
import { default as client_45cabinetSmramCG06BMeta } from "/app/apps/main/pages/features/client-cabinet.vue?macro=true";
import { default as crmjQQDQZYNJrMeta } from "/app/apps/main/pages/features/crm.vue?macro=true";
import { default as online_45recordI6Ty3InfF8Meta } from "/app/apps/main/pages/features/online-record.vue?macro=true";
import { default as services_45with_45applicationsSVbplky9tgMeta } from "/app/apps/main/pages/features/services-with-applications.vue?macro=true";
import { default as shopkVBbHRJJGDMeta } from "/app/apps/main/pages/features/shop.vue?macro=true";
import { default as websiteHuL6hWGxPrMeta } from "/app/apps/main/pages/features/website.vue?macro=true";
import { default as index6Tezf4xDR0Meta } from "/app/apps/main/pages/index.vue?macro=true";
import { default as questionszzlBDIjGzhMeta } from "/app/apps/main/pages/questions.vue?macro=true";
import { default as ratesMvfz8jSJahMeta } from "/app/apps/main/pages/rates.vue?macro=true";
export default [
  {
    name: "about-cookie",
    path: "/about/cookie",
    component: () => import("/app/apps/main/pages/about/cookie.vue")
  },
  {
    name: "about-license-agreement",
    path: "/about/license-agreement",
    component: () => import("/app/apps/main/pages/about/license-agreement.vue")
  },
  {
    name: "about-privacy-policy",
    path: "/about/privacy-policy",
    component: () => import("/app/apps/main/pages/about/privacy-policy.vue")
  },
  {
    name: "about-user-agreement",
    path: "/about/user-agreement",
    component: () => import("/app/apps/main/pages/about/user-agreement.vue")
  },
  {
    name: "client-about-contacts",
    path: "/client/about/contacts",
    meta: contactsJOJFOzPHdXMeta || {},
    component: () => import("/app/apps/main/pages/client/about/contacts.vue")
  },
  {
    name: "client-about",
    path: "/client/about",
    meta: indexBs7SYpuJobMeta || {},
    component: () => import("/app/apps/main/pages/client/about/index.vue")
  },
  {
    name: "client-about-questions",
    path: "/client/about/questions",
    meta: questionsZkYGPOPQK7Meta || {},
    component: () => import("/app/apps/main/pages/client/about/questions.vue")
  },
  {
    name: "client-about-rates",
    path: "/client/about/rates",
    meta: ratesDA2lxZX7FqMeta || {},
    component: () => import("/app/apps/main/pages/client/about/rates.vue")
  },
  {
    name: "client",
    path: "/client",
    component: () => import("/app/apps/main/pages/client/index.vue")
  },
  {
    name: "client-profile",
    path: "/client/profile",
    meta: profilehwEWxnxaMzMeta || {},
    component: () => import("/app/apps/main/pages/client/profile.vue")
  },
  {
    name: "client-projects",
    path: "/client/projects",
    meta: projectsTzwPWuFgDlMeta || {},
    component: () => import("/app/apps/main/pages/client/projects.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/apps/main/pages/contacts.vue")
  },
  {
    name: "features-bot",
    path: "/features/bot",
    component: () => import("/app/apps/main/pages/features/bot.vue")
  },
  {
    name: "features-chats",
    path: "/features/chats",
    component: () => import("/app/apps/main/pages/features/chats.vue")
  },
  {
    name: "features-client-cabinet",
    path: "/features/client-cabinet",
    component: () => import("/app/apps/main/pages/features/client-cabinet.vue")
  },
  {
    name: "features-crm",
    path: "/features/crm",
    component: () => import("/app/apps/main/pages/features/crm.vue")
  },
  {
    name: "features-online-record",
    path: "/features/online-record",
    component: () => import("/app/apps/main/pages/features/online-record.vue")
  },
  {
    name: "features-services-with-applications",
    path: "/features/services-with-applications",
    component: () => import("/app/apps/main/pages/features/services-with-applications.vue")
  },
  {
    name: "features-shop",
    path: "/features/shop",
    component: () => import("/app/apps/main/pages/features/shop.vue")
  },
  {
    name: "features-website",
    path: "/features/website",
    component: () => import("/app/apps/main/pages/features/website.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index6Tezf4xDR0Meta || {},
    component: () => import("/app/apps/main/pages/index.vue")
  },
  {
    name: "questions",
    path: "/questions",
    component: () => import("/app/apps/main/pages/questions.vue")
  },
  {
    name: "rates",
    path: "/rates",
    component: () => import("/app/apps/main/pages/rates.vue")
  }
]