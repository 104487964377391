const useErrorForNotification = () => {
  const { openNotification } = useNotificationStore();
  const route = useRoute();
  const router = useRouter();

  if (route.query?.[SHARED_URL_QUERY_PARAMETERS.ErrorForNotification]) {
    openNotification(route.query[SHARED_URL_QUERY_PARAMETERS.ErrorForNotification], {
      type: "error",
    });

    const queryParameters = copyData(route.query);

    delete queryParameters[SHARED_URL_QUERY_PARAMETERS.ErrorForNotification];

    router.push({
      query: queryParameters,
    });
  }
};

export { useErrorForNotification };
