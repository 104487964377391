export default defineNuxtRouteMiddleware(async () => {
  const { $env } = useNuxtApp();

  if ($env.isClient) {
    return;
  }

  const { getRefreshToken, removeRefreshToken, setRefreshTokenCookie } = useAuthToken();
  const { expiresRefreshToken, refreshToken } = getRefreshToken();

  if (refreshToken.value && expiresRefreshToken.value) {
    const dateNow = generateDate();
    const expiresDate = generateDate(expiresRefreshToken.value);
    const diff = expiresDate.diff(dateNow, "day");
    let updateTokenResponseState = false;
    let updateTokenResponseBody: AuthToken | null = null;

    if (diff <= 7) {
      const { body, success } = await useAuthUpdateRefreshTokenDto({
        refresh_token: refreshToken.value,
      });

      if (body.data) {
        setRefreshTokenCookie(body.data);
      }

      updateTokenResponseState = success;
      updateTokenResponseBody = body.data;
    } else {
      const { body, success } = await useAuthUpdateAccessTokenDto({
        refresh_token: refreshToken.value,
      });

      updateTokenResponseState = success;
      updateTokenResponseBody = body.data;
    }

    if (updateTokenResponseBody) {
      const { setAuthToken } = useAuthStore();

      setAuthToken(updateTokenResponseBody);
    }

    if (!updateTokenResponseState) {
      const { createAppLink } = useAppLink();
      const { hash, pathname, search } = useRequestURL();
      const redirectLink = createAppLink("/", "main");
      const redirectToLink = createAppLink(pathname + search + hash, "main");

      removeRefreshToken();

      return navigateTo(
        {
          path: redirectLink,
          query: {
            modal: SHARED_MODAL.AuthLogin,
            [SHARED_URL_QUERY_PARAMETERS.RedirectTo]: redirectToLink,
          },
        },
        {
          external: true,
        },
      );
    }
  }
});
