import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/app/apps/main/middleware/01.auth.global.ts";
import _02_45access_45global from "/app/apps/main/middleware/02.access.global.ts";
import _03_45client_45global from "/app/apps/main/middleware/03.client.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45access_45global,
  _03_45client_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}