<template>
  <svg
    ref="iconElement"
    aria-hidden="true"
    class="base-icon"
    focusable="false"
  >
    <slot>
      <use
        v-if="href"
        :href
      />

      <use
        v-else-if="id"
        :href="`${appConfig.components.icons.spritesDir}/${sprite}.svg#${id}`"
      />
    </slot>
  </svg>
</template>

<script lang="ts" setup>
import type { SVGAttributes } from "vue";

interface Properties extends /* @vue-ignore */ SVGAttributes {
  href?: SVGAttributes["href"];
  id?: SVGAttributes["id"];
  sprite?: string;
}

withDefaults(defineProps<Properties>(), {
  href: "",
  id: "",
  sprite: "icons",
});

defineSlots<{
  default: [];
}>();

const appConfig = useAppConfig();
const iconElement = ref<null | SVGElement>(null);

defineExpose({
  iconElement,
});
</script>
