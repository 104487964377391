import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import duration from "dayjs/plugin/duration";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";
import timezone from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";
import dayjsBusinessTime from "./dayjs-business-days.js";
dayjs.extend(dayjsBusinessTime);
dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(utcPlugin);
dayjs.extend(timezone);
dayjs.locale(ru);
const generateDate = (date = "now", { userTimezone = false, utc = false } = {}) => {
  const userTz = dayjs.tz.guess();
  let currentDate = null;
  if (utc) {
    currentDate = dayjs.utc(date);
  } else if (date === "now") {
    currentDate = dayjs();
  } else {
    currentDate = Number.isNaN(Number(date)) ? dayjs(date) : dayjs.unix(Number(date));
  }
  return userTimezone ? currentDate : dayjs.tz(currentDate, userTz);
};
const generateDateIsToday = (date) => {
  return generateDate(date).isSame(generateDate(), "day");
};
const generateDateIsTomorrow = (date) => {
  return generateDate(date).isSame(generateDate().add(1, "day"), "day");
};
const generateDateIsYesterday = (date) => {
  return generateDate(date).isSame(generateDate().add(-1, "day"), "day");
};
const generateLastDate = () => {
  return generateDate("31 Dec 9999", {
    userTimezone: true
  }).toDate();
};
const getDaysInMonth = ({
  date = null,
  format = "",
  position = null,
  type = null
} = {}) => {
  const monthEnd = generateDate(date).endOf(`month`);
  const days = [];
  let currentDay = generateDate(date).startOf(`month`);
  let monthComplete = false;
  const checkDay = (day) => {
    switch (position) {
      case "after": {
        return generateDate().isBefore(day, "day");
      }
      case "before": {
        return generateDate().isAfter(day, "day");
      }
    }
  };
  while (!monthComplete) {
    switch (type) {
      case "weekday": {
        if (!currentDay.isBusinessDay() && (position ? checkDay(currentDay) : true)) {
          days.push(currentDay.format(format));
        }
        break;
      }
      case "workday": {
        if (currentDay.isBusinessDay() && (position ? checkDay(currentDay) : true)) {
          days.push(currentDay.format(format));
        }
        break;
      }
    }
    currentDay = currentDay.add(1, `day`);
    if (currentDay.isAfter(monthEnd)) {
      monthComplete = true;
    }
  }
  return days;
};
export {
  generateDate,
  generateDateIsToday,
  generateDateIsTomorrow,
  generateDateIsYesterday,
  generateLastDate,
  getDaysInMonth
};
export { default as generateDateLibrary } from "dayjs";
