const useAuthUpdateRefreshTokenDto = async (payload: UpdateRefreshTokenPayload) => {
  const response = await useFetchData<FetchData<AuthToken>>("/auth/refresh-token", {
    body: payload,
    method: "POST",
  });

  return {
    ...response,
  };
};

export { useAuthUpdateRefreshTokenDto };
