export default defineNuxtPlugin({
  name: "env",
  setup: () => {
    return {
      provide: {
        env: {
          isClient: import.meta.client,
          isDev: import.meta.dev,
          isPrerender: import.meta.prerender,
          isProd: !import.meta.dev,
          isServer: import.meta.server,
        },
      },
    };
  },
});
